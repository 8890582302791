<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style="min-height: calc(100vh - 83px);"
  >
    <q-card flat bordered style="width:1024px" class="q-mt-xl">
      <q-tabs
        v-model="tab"
        dense
        align="justify"
        @input="refreshPatok(), refreshPage()"
        no-caps
        class="bg-primary text-white"
      >
        <q-tab name="musyrif" label="Musyrif/ah"></q-tab>
        <q-tab name="tahfidz" label="Tahfidz"></q-tab>
        <q-tab name="mentor" label="Mentor"></q-tab>
      </q-tabs>
      <q-card-section class="row justify-between">
        <q-select
          :options="$LIST_JENJANG"
          v-model="selJenjang"
          @input="refreshData"
          outlined
          label="Jenjang"
          style="width: 310px;"
        ></q-select>
        <q-select
          :options="$LIST_PENGASUHAN"
          v-model="selPengasuhan"
          @input="refreshKanan"
          outlined
          label="Jenjang"
          style="width: 310px;"
        ></q-select>
      </q-card-section>
      <q-card-section class="row justify-between q-pt-none">
        <div class="row q-gutter-sm">
          <q-select
            :options="listTingkat"
            v-model="selTingkat"
            @input="getSiswaList(), (selMusyrif = null), (listKanan = [])"
            outlined
            label="Tingkat"
            style="width: 150px;"
          ></q-select>
          <q-input outlined label="Cari Siswa/NISN" v-model="searchTerm">
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </div>

        <q-select
          class="self-end"
          use-input
          v-if="tab == 'musyrif'"
          @filter="filterFn"
          :options="filteredlistMusyrif"
          v-model="selMusyrif"
          outlined
          @input="getSiswaMusyrif()"
          label="Musyrif/ah"
          style="width: 200px;"
        ></q-select>
        <q-select
          class="self-end"
          use-input
          v-if="tab == 'tahfidz'"
          @filter="filterFn"
          :options="filteredlistMusyrif"
          v-model="selMusyrif"
          outlined
          @input="getSiswaMusyrif()"
          label="Murabbi Tahfidz"
          style="width: 200px;"
        ></q-select>
        <q-select
          class="self-end"
          use-input
          v-if="tab == 'mentor'"
          @filter="filterFn"
          :options="filteredlistMusyrif"
          v-model="selMusyrif"
          outlined
          @input="getSiswaMusyrif()"
          label="Murabbi Mentor"
          style="width: 200px;"
        ></q-select>
      </q-card-section>
      <q-card-section class="row justify-between items-center">
        <q-markup-table
          flat
          bordered
          dense
          separator="horizontal"
          style="width:45%"
          class="stickyTable bg-grey-4"
        >
          <thead class="bg-indigo-5 text-white text-left">
            <tr>
              <th style="width:20px;">
                <q-checkbox
                  @input="checkingAll('kiri')"
                  v-model="checkAllKiri"
                ></q-checkbox>
              </th>
              <th>no</th>
              <th>nisn</th>
              <th>nama</th>
            </tr>
          </thead>
          <tbody class="bg-white">
            <tr v-for="(val, i) in filSiswa" :key="i">
              <td>
                <q-checkbox v-model="val.check"></q-checkbox>
              </td>
              <td>{{ i + 1 }}</td>
              <td>{{ val.nisn }}</td>
              <td>{{ val.nama }}</td>
            </tr>
          </tbody>
        </q-markup-table>
        <div class="column q-gutter-sm">
          <q-btn
            outline
            color="black"
            :disable="selMusyrif == null"
            @click="(isChanged = true), toGlobal()"
            icon="keyboard_arrow_left"
          ></q-btn>
          <q-btn
            outline
            color="black"
            :disable="selMusyrif == null"
            @click="(isChanged = true), toMusyrif()"
            icon="keyboard_arrow_right"
          ></q-btn>
        </div>
        <q-markup-table
          flat
          bordered
          dense
          separator="horizontal"
          style="width:45%"
          class="stickyTable bg-grey-4"
        >
          <thead class="bg-indigo-5 text-white text-left">
            <tr>
              <th style="width:20px;">
                <q-checkbox
                  :disable="selMusyrif == ''"
                  @input="checkingAll('kanan')"
                  v-model="checkAllKanan"
                ></q-checkbox>
              </th>
              <th>no</th>
              <th>nisn</th>
              <th>nama</th>
            </tr>
          </thead>
          <tbody class="bg-white">
            <tr v-show="!selMusyrif">
              <td colspan="4" class="text-caption text-grey-7 text-center">
                Silahkan pilih Musyrif/ah terlebih dahulu
              </td>
            </tr>
            <tr v-show="listKanan.length == 0 && selMusyrif">
              <td colspan="4" class="text-caption text-grey-7 text-center">
                Tidak Ada Siswa Dalam Musyrif/ah Terpilih
              </td>
            </tr>
            <tr v-for="(val, i) in listKanan" :key="i">
              <td>
                <q-checkbox v-model="val.check"></q-checkbox>
              </td>
              <td>{{ i + 1 }}</td>
              <td>{{ val.nisn }}</td>
              <td>{{ val.nama }}</td>
            </tr>
          </tbody>
        </q-markup-table>
      </q-card-section>
      <q-card-actions align="left">
        <q-btn
          outline
          color="primary"
          label="Import Excel"
          @click="showDialogImport"
        ></q-btn>
      </q-card-actions>
    </q-card>
    <q-dialog v-model="show" persistent>
      <q-card>
        <q-card-section class="row items-center">
          <p>Apa anda yakin ingin menyimpan perubahan?</p>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            flat
            label="Ya"
            color="primary"
            @click="simpan"
            v-close-popup
          />
          <q-btn flat label="Tidak" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import DialogImportKelasKamar from "@/components/DialogImportKelasKamar";
import General from "@/mixins/General";
export default {
  mixins: [General],
  data() {
    return {
      tab: "musyrif",
      TIPE_IS: "is_musyrif",
      TIPE_ID: "id_musyrif",

      file: null,
      insert: true,

      show: false,
      isChanged: false,
      checkAllKanan: false,
      checkAllKiri: false,

      selJenjang: this.$LIST_JENJANG[0],
      selPengasuhan: this.$LIST_PENGASUHAN[0],

      listTingkat: [],
      selTingkat: "",

      listMusyrif: [],
      filteredlistMusyrif: [],
      selMusyrif: null,

      listKiri: [],
      listKanan: [],
      searchTerm: "",
    };
  },
  computed: {
    filSiswa() {
      if (this.searchTerm == "") return this.listKiri;
      else {
        return this.listKiri.filter((val) => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          let cNISN = val.nisn.indexOf(this.searchTerm) != -1;
          return cNama || cNISN;
        });
      }
    },
  },
  // watch: {
  //   tab: function(val) {
  //     if (val == "musyrif") {
  //       this.TIPE_IS = "is_musyrif";
  //       this.TIPE_ID = "id_musyrif";
  //     } else if (val == "tahfidz") {
  //       this.TIPE_IS = "is_murabbi_tahfidz";
  //       this.TIPE_ID = "id_murabbi_tahfidz";
  //     } else if (val == "mentor") {
  //       this.TIPE_IS = "is_murabbi_mentor";
  //       this.TIPE_ID = "id_murabbi_mentor";
  //     }
  //   }
  // },
  async mounted() {
    await this.refreshData();
    await this.refreshKanan();
  },
  methods: {
    refreshPatok() {
      if (this.tab == "musyrif") {
        this.TIPE_IS = "is_musyrif";
        this.TIPE_ID = "id_musyrif";
      } else if (this.tab == "tahfidz") {
        this.TIPE_IS = "is_murabbi_tahfidz";
        this.TIPE_ID = "id_murabbi_tahfidz";
      } else if (this.tab == "mentor") {
        this.TIPE_IS = "is_murabbi_mentor";
        this.TIPE_ID = "id_murabbi_mentor";
      }
    },
    async refreshPage() {
      this.selJenjang = this.$LIST_JENJANG[0];
      this.selPengasuhan = this.$LIST_PENGASUHAN[0];
      await this.refreshData();
      await this.refreshKanan();
    },
    async refreshData() {
      this.listKiri = [];
      await this.getTingkatList();
      await this.getSiswaList();
    },
    async refreshKanan() {
      this.selMusyrif = null;
      this.listKanan = [];
      this.listMusyrif = [];
      await this.getListMusyrif();
    },
    async simpan() {
      this.$q.loading.show({ message: "Menyimpan Data" });
      let idIn = [];
      let idOut = [];
      this.listKanan.forEach((number, index) => {
        idIn.push(number.id);
      });

      this.listKiri.forEach((number, index) => {
        idOut.push(number.id);
      });

      await this.$http.put(
        `/pengasuhan/isi_musyrif/isi/${this.TIPE_ID}/${this.selMusyrif.value}`,
        idIn,
        this.$hlp.getHeader()
      );

      await this.$http.put(
        `/pengasuhan/isi_musyrif/isi/${this.TIPE_ID}`,
        idOut,
        this.$hlp.getHeader()
      );

      this.$q.notify({
        message: "proses menyimpan data berhasil",
        color: "positive",
      });
      this.$q.loading.hide();
    },
    showDialogImport() {
      this.$q
        .dialog({
          component: DialogImportKelasKamar,
          parent: this,
          asal: "musyrif",
        })
        .onOk(async (resp) => {
          let file = await this.$hlp.ReadXLSX(resp.file);
          this.tab = resp.tipe.tab;
          await this.refreshPatok();
          await this.refreshPatok();
          this.fileReady(file, resp.tipe);
        });
    },
    async fileReady(file, tipe) {
      this.$q.loading.show({ message: "please wait" });
      let listMusyrif = [];
      let rows = file.split("\n");
      for (let item of rows) {
        let temp = item.split(";");
        if (temp[0] != "") {
          if (parseInt(temp[0]) > 0) {
            let carier = {};
            carier.id = temp[0];
            carier.gender = temp[3];
            carier.musyrif = temp[5];
            carier.id_musyrif = -1;
            listMusyrif.push(carier);
          }
        }
      }

      let resp = null;

      resp = await this.$http.get(
        `/pengasuhan/isi_musyrif/getmusyrif/PENGASUHAN PUTRA/${tipe.tipe_is}`
      );
      let musyrif_putra = resp.data;

      resp = await this.$http.get(
        `/pengasuhan/isi_musyrif/getmusyrif/PENGASUHAN PUTRI/${tipe.tipe_is}`
      );
      let musyrif_putri = resp.data;

      let siswa_putra = listMusyrif.filter((val) => {
        return val.gender == "L";
      });

      let siswa_putri = listMusyrif.filter((val) => {
        return val.gender == "P";
      });

      for (let musyrif of musyrif_putra) {
        for (let siswa of siswa_putra) {
          if (siswa.musyrif == musyrif.label) {
            siswa.id_musyrif = musyrif.value;
          }
        }
      }

      for (let musyrif of musyrif_putri) {
        for (let siswa of siswa_putri) {
          if (siswa.musyrif == musyrif.label) {
            siswa.id_musyrif = musyrif.value;
          }
        }
      }

      await this.$http.put(`/pengasuhan/isi_musyrif/inputexcel`, siswa_putra);
      await this.$http.put(`/pengasuhan/isi_musyrif/inputexcel`, siswa_putri);
      this.$q.loading.hide();
      this.MNotifyPositive("Berhasil import data excel");
      this.refreshPage();
      this.selMusyrif = null;
      this.listKanan = [];
    },

    filterFn(val, update) {
      if (val === "") {
        update(() => {
          this.filteredlistMusyrif = this.listMusyrif;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.filteredlistMusyrif = this.listMusyrif.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    toMusyrif() {
      let temp = [];
      for (let item in this.listKiri) {
        if (this.listKiri[item].check) {
          temp.push(this.listKiri[item].id);
          this.listKanan.push(this.listKiri[item]);
        }
      }
      for (let item in temp) {
        this.listKiri = this.listKiri.filter(function(x) {
          return x.id != temp[item];
        });
      }
      this.simpan();
      this.$q.notify({
        message: "proses pemindahan siswa ke kamar berhasil",
        color: "positive",
      });
    },
    toGlobal() {
      let temp = [];
      for (let item in this.listKanan) {
        if (this.listKanan[item].check) {
          temp.push(this.listKanan[item].id);
          this.listKiri.push(this.listKanan[item]);
        }
      }
      for (let item in temp) {
        this.listKanan = this.listKanan.filter(function(x) {
          return x.id != temp[item];
        });
      }
      this.simpan();
      this.$q.notify({
        message: "proses pengeluaran siswa dari kamar berhasil",
        color: "positive",
      });
    },
    checkingAll(pos) {
      if (pos == "kanan") {
        if (this.checkAllKanan) {
          for (let item in this.listKanan) {
            this.listKanan[item].check = true;
          }
        } else {
          for (let item in this.listKanan) {
            this.listKanan[item].check = false;
          }
        }
      } else if (pos == "kiri") {
        this.$q.loading.show({ message: "please wait" });
        if (this.checkAllKiri) {
          for (let item in this.listKiri) {
            this.listKiri[item].check = true;
          }
        } else {
          for (let item in this.listKiri) {
            this.listKiri[item].check = false;
          }
        }
        this.$q.loading.hide();
      }
    },
    async getListMusyrif() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/pengasuhan/isi_musyrif/getmusyrif/${this.selPengasuhan}/${this.TIPE_IS}`,
        this.$hlp.getHeader()
      );
      this.$q.loading.hide();

      this.listMusyrif = resp.data;
    },
    async getTingkatList() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        "/pengasuhan/kamar/gettingkat/" + this.selJenjang,
        this.$hlp.getHeader()
      );

      this.listTingkat = resp.data;
      this.selTingkat = resp.data[this.listTingkat.length - 1];
      this.$q.loading.hide();
    },
    async getSiswaList() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/pengasuhan/isi_musyrif/getsiswa/${this.selJenjang}/${this.selTingkat.value}/${this.TIPE_ID}`,
        this.$hlp.getHeader()
      );

      for (let el of resp.data) {
        el.check = false;
      }
      this.listKiri = resp.data;
      this.$q.loading.hide();
    },
    async getSiswaMusyrif() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/pengasuhan/isi_musyrif/getsiswa/${this.selJenjang}/${this.selTingkat.value}/${this.TIPE_ID}/${this.selMusyrif.value}`
      );
      resp.data.forEach((el) => {
        el.check = false;
      });
      this.listKanan = resp.data;
      this.$q.loading.hide();
    },
  },
};
</script>

<style lang="scss" scoped></style>
